import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "../styles/styles.scss";
import {
  faCalendarAlt,
  faCalendarDay,
  faDollarSign,
  faHourglassStart,
  faChild,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const defaultImage = require("@images/mbb-logo-1-96x96.png");

export default ({
  familyID,
  location,
  startDate,
  schedule,
  hours,
  pay,
  placementLength,
  nKids,
  familydescription,
  jobDescription,
  postedDate,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const getTabStyles = (tab) => {
    if (currentTab === tab) {
      return "is-active";
    } else {
      return "";
    }
  };
  const JobDetails = (
    <div>
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCalendarDay} />
        </span>
        <span className="has-text-weight-bold"> Start Date:</span>
      </div>

      <div>{startDate}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCalendarAlt} />
        </span>
        <span className="has-text-weight-bold"> Schedule:</span>
      </div>
      <div>{schedule}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faClock} />
        </span>
        <span className="has-text-weight-bold"> Hours:</span>
      </div>
      <div>{hours}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faDollarSign} />
        </span>
        <span className="has-text-weight-bold"> Pay Rate:</span>
      </div>
      <div>{pay}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faHourglassStart} />
        </span>
        <span className="has-text-weight-bold"> Placement Length:</span>
      </div>
      <div>{placementLength}</div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faChild} />
        </span>
        <span className="has-text-weight-bold"> Children:</span>
        <span> {nKids}</span>
      </div>
      <br />
      <div>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </span>
        <span className="has-text-weight-bold"> Location:</span>
      </div>
      <div>{location}</div>
    </div>
  );
  const FamilyDescription = (
    <div>
      <div className="has-text-weight-bold has-text-centered">
        About the Family
      </div>
      <div>{familydescription}</div>
    </div>
  );
  const JobDescription = (
    <div>
      <div className="has-text-weight-bold has-text-centered">
        Position Details
      </div>
      <div>{jobDescription}</div>
    </div>
  );
  return (
    <div className="column is-6">
      <div className="card bm--card-equal-height">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={defaultImage} alt="Family image" />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">Family #{familyID}</p>
              <p className="subtitle is-6">{location}</p>
            </div>
          </div>
          <div className="tabs is-centered is-boxed">
            {/* <div className="tabs is-centered"> */}
            <ul>
              <li className={getTabStyles(0)}>
                <a onClick={() => setCurrentTab(0)}>Overview</a>
              </li>
              <li className={getTabStyles(1)}>
                <a onClick={() => setCurrentTab(1)}>Family</a>
              </li>
              <li className={getTabStyles(2)}>
                <a onClick={() => setCurrentTab(2)}>Details</a>
              </li>
            </ul>
          </div>
          <div className="content">
            <div>{currentTab == 0 && JobDetails}</div>
            <div>{currentTab == 1 && FamilyDescription}</div>
            <div>{currentTab == 2 && JobDescription}</div>
            <br />
            <div className="container has-text-centered job-card-details">
              <Link to={`/applications/nanny?familyID=${familyID}`}>
                <button className="button is-fullwidth is-link">Apply</button>
              </Link>
            </div>
            <br />
            <div>Posted {postedDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
