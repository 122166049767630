export default [  
  {
    familyID: "5892",
    location: "Longmont/Lyons, CO",
    startDate: "ASAP",
    schedule: "Ideally Monday, 8:30-5:30 Tuesday 2pm-8pm Thursday date night 2pm-9:30 Friday 8:30-5:30 2 Saturdays a month, vacation/travel with family required ",
    hours: "40+ Hours",
    pay: "$28+/hour",
    placementLength: "Long term",
    nKids: "3 children, 8 years, 3.5 years, 2 years",
    familydescription:
      "Family of 5 + dog + cat",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Oct 23rd, 2024"
  },
  {
    familyID: "2142",
    location: "Downtown Boulder, CO",
    startDate: "Jan 2025, or sooner",
    schedule: "Monday, Tuesday, Thursday, Friday from 9am-5pm, occasional day until 6pm, occasional date night or weekend hours but not required",
    hours: "32+ Hours",
    pay: "$30-40/hour depending on experience",
    placementLength: "Long term",
    nKids: "1 Infant, 7 months",
    familydescription:
      "Family of 3 + dog",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Oct 26th, 2024"
  },
  {
    familyID: "3291",
    location: "Boulder, CO",
    startDate: "Jan 6th, 2025",
    schedule: "M-F, 8:45am-5:30pm",
    hours: "43.75 Hours",
    pay: "$25-30/hour depending on experience",
    placementLength: "Long term",
    nKids: "1-2 kids, 4 years old & 5 months old",
    familydescription:
      "Family of 4, no pets",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Oct 26th, 2024"
  }
];
