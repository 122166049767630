import React from "react";
import Layout from "@components/layout/layout";
import JobCard from "@components/jobCard";
import jobs from "@data/jobs";
import "@styles/styles.scss";

export default () => {
  return (
    <Layout pageTitle="Jobs">
      <div style={{ height: "5vh" }}></div>
      <div className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered">
              <h1 className="title">Nanny Jobs</h1>
            </div>
            <div style={{ height: "8vh" }}></div>
            {jobs.map((job) => {
              return (
                <JobCard
                  key={job.familyID}
                  familyID={job.familyID}
                  location={job.location}
                  startDate={job.startDate}
                  schedule={job.schedule}
                  hours={job.hours}
                  pay={job.pay}
                  placementLength={job.placementLength}
                  nKids={job.nKids}
                  familydescription={job.familydescription}
                  jobDescription={job.jobDescription}
                  postedDate={job.postedDate}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};
